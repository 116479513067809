( function( $, window, document, undefined ) {

	const menu = $('.site-nav__list');

	$.fn.focusWithoutScrolling = function(){
	  var x = window.scrollX, y = window.scrollY;
	  this.focus();
	  window.scrollTo(x, y);
	  return this; //chainability
	};

	// Select all links with hashes
	$('a[href*="#"]')
	  // Remove links that don't actually link to anything
	  .not('[href="#"]')
	  .not('[href="#0"]')
	  .click(function(event) {
	    // On-page links
	    if ( menu.hasClass('is-open') ) {
			closeMenu();
		}

	    if (
	      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
	      location.hostname == this.hostname
	    ) {
	      // Figure out element to scroll to
	      var target = $(this.hash);
	      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
	      // Does a scroll target exist?
	      if (target.length) {
	        // Only prevent default if animation is actually gonna happen
	        event.preventDefault();
	        $('html, body').animate({
	          scrollTop: target.offset().top
	        }, 1000, function() {
	          // Callback after animation
	          // Must change focus!
	          var $target = $(target);
	          $target.focus();
	          if ($target.is(":focus")) { // Checking if the target was focused
	            return false;
	          } else {
	            $target.parent().attr('tabindex','-1'); // Adding tabindex for elements not focusable
	            $target.parent().focusWithoutScrolling(); // Set focus again
	          }
	        });
	      }
	    }
	  });

} )( jQuery, window, document );
