( function( $, window, document, undefined ) {

const hamburger = $('.hamburger');
const menu = $('.site-nav__list');

hamburger.on('click touchend', function(event) {
  event.preventDefault();

  if ( menu.hasClass('is-open') ) {
  	closeMenu();
  } else {
  	openMenu();
  }

})

window.closeMenu = function() {
	menu.stop().slideUp(200);
	toggleOpen();
}

window.openMenu = function() {
	menu.stop().slideDown(200);
	toggleOpen();
}

function toggleOpen() {
	menu.toggleClass('is-open');
	hamburger.toggleClass('is-active');
}

} )( jQuery, window, document );